.map {
    width: 100%;
    max-width: 100%;
}

.map-selected {
    fill: #E3DA37;
  }
  
  .map-unselected {
    fill: #F4F4F4;
  }
  
  .map-selected:hover, .map-unselected:hover {
    cursor: pointer;
  }