.slider {
    width: 100%;
  }
  
  .slider__track,
  .slider__range,
  .slider__left-value,
  .slider__right-value {
    position: absolute;
    width: 100%;
  }
  
  .slider__track,
  .slider__range {
    border-radius: 3px;
    height: 5px;
  }
  
  .slider__track {
    width: 300px;
    max-width: 350px;
    min-width: 200px;
    z-index: 1;
  }
  
  .slider__range {
    z-index: 2;
    
  }
  
  .slider__left-value,
  .slider__right-value {
    margin-top: 20px;
  }
  
  .slider__left-value {
    left: 6px;
  }
  
  .slider__right-value {
    right: -4px;
  }
  
  /* Removing the default appearance */
  .thumb-range,
  .thumb-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent
  }
  
  .thumb-range {
    pointer-events: none;
    background-color: #828282;
    align-items: center;
    height: 7px;
    max-width: 350px;
  }
  
  .thumb--zindex-3 {
    z-index: 3;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  
  .thumb--zindex-4 {
    z-index: 4;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  .thumb--zindex-5 {
    z-index: 5;
  }
  
  /* For Chrome browsers */
  .thumb-range::-webkit-slider-thumb {
    background-color: #FFFFFF;
    border: 2px solid #FFBD59;
    border-radius: 50%;
    box-shadow: #FFBD59;
    align-items: center;
    cursor: pointer;
    height: 20px;
    width: 20px;
    pointer-events: all;
    position: relative;
  }
  
  /* For Firefox browsers */
  .thumb-range::-moz-range-thumb {
    background-color: #FFFFFF;
    border: 2px solid #FFBD59;
    border-radius: 50%;
    box-shadow: #FFBD59;
    align-items: center;
    cursor: pointer;
    height: 18px;
    width: 18px;
    pointer-events: all;
    position: relative;
  }